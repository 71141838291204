//
//
//
//


import functions from "@/functions";
import store from "@/store";

export default {
  name: "Test",
  components: {},
  data: function () {
    return {

    }
  },
  methods: {

  },
  computed: {

  },
}
